@media screen and (max-width: 900px) {
  .projects {
    /* height: 100vh; */
    background-color: #010401;
    color: #efefef;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
  }
  .projects-left {
    flex: 1;
    padding: 5vh 5vw;
    align-self: flex-end;
  }
}

@media screen and (min-width: 899px) {
  .projects {
    height: 100vh;
    background-color: #010401;
    color: #efefef;
    display: flex;
    flex-direction: row;
    max-width: 100vw;
  }
  .projects-left {
    flex: 1;
    padding: 5vh 5vw 30vh 5vw;
    align-self: flex-end;
  }
}

.projects-right {
  flex: 2;
  display: block;
  margin: 5vh 0;
  padding-right: 3vw;
}

.projects-header {
  font-size: 40px;
  font-family: "Fira Code", sans-serif;
}

.project {
  height: 100vh;
}

.project-header {
  font-size: 40px;
  font-family: "Fira Code", sans-serif;
}
