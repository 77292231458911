.contact {
  height: 30vh;
  background-color: #010401;
  color: #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
.contact-button {
  margin: auto;
  border: 2px solid #efefef;
  color: #efefef;
  text-decoration: none;
  padding: 1vh 2vw;
  transition: all 750ms;
}

.contact-button:hover {
    color: #010401;
    background-color: #efefef;
}

.social-links {
  padding: 5vh 1vw 5vh 1vw;
}

.social-links a {
  padding: 1vh 1vw;
  color: #efefef;

}
  

.divider {
  color: #efefef;
  width: 90%;
}

.logo{
    max-height: 8vh;
    object-fit: contain;
}