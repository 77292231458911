
@media screen and (max-width: 900px) {
  .about {
    /* height: 100vh; */
    background: #010401;
    color: #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;
  }  
}

@media screen and (min-width: 899px) {
  .about {
    height: 100vh;
    background: #010401;
    color: #efefef;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
    
}

.about-left {
  flex: 1;
  height: 100%;
}

.about-right {
  flex: 2;
  padding: 3vh 5vw 3vh 3vw;
}

.about-heading {
  font-family: "fira code", sans-serif;
  font-size: 40px;
  padding: 5vh 5vw 5vh 0px;
}

.about-skills {
  background-color: #efefef;
  height: 100%;
  overflow: hidden;
}

.about-skills-wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 50%);
  grid-template-rows: 33.3% 33.3% 33.3%;
  grid-auto-rows: 0px;
  grid-template-columns: 50% 50% 50% 50% 50% 50% 50%;
  animation: moveSkills 10s ease-out infinite alternate;
  animation-delay: 3s;
}

.skill-logo {
  padding: 10%;
  margin: auto;
  max-height: 30%;
  max-width: 30%;
}

@keyframes moveSkills {
  33% {
    transform: translateX(-50%);
  }

  66% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-150%);
  }
}
