.intro {
  display: flex;
  height: 100vh;
  background-color: #010401;
  color: #efefef;
}

.intro-heading {
  font-size: 30px;
  font-weight: 300;
}

.intro-title {
  height: 50px;
  overflow: hidden;
}

.intro-title-wrapper {
  height: 100%;
  animation: move 15s ease-out infinite alternate;
}

.intro-title-item {
  height: 50px;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.intro-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-left-wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 1399px) {
  .intro-right {
    flex: 1;
    position: relative;
  }

  .intro-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    clip-path: polygon(
      25% 12.5%,
      75% 12.5%,
      100% 50%,
      75% 87.5%,
      25% 87.5%,
      0% 50%
    );
    transition: all 1s ease-in-out;
    background: url("../../images/lc-2.png");
    background-repeat: no-repeat;
  }

  .intro-right:hover .intro-img {
    transform: translateY(-20px);
  }

  .intro-right:hover .intro-bg {
    transform: translateX(40px);
  }

  .intro-bg {
    clip-path: polygon(
      25% 12.5%,
      75% 12.5%,
      100% 50%,
      75% 87.5%,
      25% 87.5%,
      0% 50%
    );
    background-color: #efefef;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 12.5%;
    transition: all 1s ease-in-out;
  }
}

@keyframes move {
  10% {
    transform: translateY(-50px);
  }

  20% {
    transform: translateY(-100px);
  }

  30% {
    transform: translateY(-150px);
  }

  40% {
    transform: translateY(-200px);
  }

  50% {
    transform: translateY(-250px);
  }

  60% {
    transform: translateY(-300px);
  }

  70% {
    transform: translateY(-350px);
  }

  80% {
    transform: translateY(-400px);
  }

  90% {
    transform: translateY(-450px);
  }

  100% {
    transform: translateY(-500px);
  }
}
